import React from "react"
import ClassificationRow  from "./ClassificationRow.js"
import ClassificationHead from "./ClassificationHead.js"

export default class ClassificationSelect extends React.Component {
  state = {
    activeClassifications: this.props.allClassifications
  }

  selectClassification = (selectedId) => {
    let selected, activeClassifications
    let show = false
    if (selectedId) {
      const classifications = this.props.allClassifications
      // Get the selected on a tree structure
      selected = classifications.find(c => c.id === selectedId)

      if (!selected) {
        classifications.forEach(cl => {
          if(!selected) {
            selected = cl.children.find(ch => ch.id === selectedId)
          }
          if(!selected) {
            cl.children.forEach(child => {
              if(!selected) {
                selected = child.children.find(chi => chi.id === selectedId)
              }
            })
          }
        })
      }

      if (!selected) {debugger}

      if (selected.selectable) {
        show = true
        activeClassifications = [selected]
      } else {
        activeClassifications = selected.children
      }
    } else {
      activeClassifications = this.props.allClassifications
    }
    this.setState({
      show: show,
      selected: selected,
      activeClassifications: activeClassifications
    })
  }

  setClassification = (classification) => {
    this.setState({
      show: true,
      selected: classification,
      activeClassifications: [classification]
    })
  }

  componentDidMount() {
    this.selectClassification(this.props.selectedId)
  }

  render () {
    const {activeClassifications, selected, show} = this.state
    return (
      <div className="from-group">
        <table className={show ? "table" : "table table-hover"}>
          { selected && !show
              ?
              <ClassificationHead
                classification={selected}
                clickClassification={this.selectClassification}
              />
              :
              !show
                ?
                <thead>
                  <tr>
                    <th>Código</th>
                    <th>Clasificaciones ATC</th>
                  </tr>
                </thead>
                :
                <thead>
                  <tr>
                    <th>Código</th>
                    <th>Clasificación seleccionada</th>
                  </tr>
                </thead>
          }
          <tbody>
            {activeClassifications.map((classification) =>
              <ClassificationRow
                key={classification.id}
                classification={classification}
                clickClassification={this.selectClassification}
                show={show}
              />
            )}
          </tbody>
        </table>
      </div>
    )
  }
}
