import React from "react"
import DataTable from 'react-data-table-component';

export default class UsersTable extends React.Component {
  state = {
    data: this.props.data,
    loading: false
  }

  render () {
    const columns = [
      {
        name: 'Email',
        selector: 'email',
        sortable: true,
        hide: "sm"
      },
      {
        name: 'Nombre',
        selector: 'name',
        sortable: true
      },
      {
        name: 'Apellidos',
        selector: 'surname',
        sortable: true,
        hide: "sm",
      },
      {
        name: 'Cambio el password',
        selector: 'changed_password',
        sortable: true,
        hide: "sm",
      },
      {
        name: '',
        sortable: false,
        hide: 'sm',
        right: true,
        width: "25%",
        cell: user =>
          <div className="d-flex justify-content-end">
            <a href={`/admin/colaboradores/${user.id}/editar`}
               className="btn btn-info"
            >
              <i className="fas fa-edit"></i>
            </a>

            <a href={`/admin/colaboradores/${user.id}/reset_password`}
               className="btn btn-info ml-2"
               title="Restablecer contraseña"
               data-confirm="¿Está seguro de que quieres restablecer la contraseña?"
               data-method="patch"
            >
              <i className="fas fa-unlock-alt"></i>
            </a>

            {
              !user.super_admin &&
              <a
                href={`/admin/colaboradores/${user.id}`}
                className="btn btn-danger ml-2"
                data-confirm="¿Está seguro de que quieres borrar al colaborador?"
                data-method="delete"
              >
                <i className="fas fa-trash-alt"></i>
              </a>
            }
          </div>
      }
    ]

    return (
      <DataTable
        title="Colaboradores"
        columns={columns}
        data={this.state.data}
        noDataComponent="No hay colaboradores"
        noTableHead={screen.width < 600}
        noHeader={screen.width < 600}
        progressPending={this.state.loading}

        highlightOnHover={true}
      />
    )
  }
}
