import React, { useState, useEffect, useRef } from 'react'

export const DrugSymptom = ({ drugSymptom }) => {
  const [active, setActive] = useState(false)
  const symptomsRef = useRef(null)

  const onClick = e => {
    e.preventDefault()
    e.stopPropagation()
    setActive(!active)
  }

  const addTableClass = () => {
    const ref = symptomsRef.current
    if(ref) {
      const tables = ref.querySelectorAll("table")
      tables.forEach(table => table.classList.add("table"))
    }
  }

  useEffect(addTableClass, [active])

  const badgeClass = drugSymptom.selected ? 'badge-success' : 'badge-danger'

  if (drugSymptom.has_text && !drugSymptom.free_text) {
    return(<React.Fragment></React.Fragment>)
  }

  return(
    <div className={`list-group-item list-group-item-action ${active ? 'no-hover' : ''}`}
      key={drugSymptom.symptom.id}
      onClick={onClick}
    >
      <span className={`badge ${badgeClass} badge-pill`}>
        &nbsp;
      </span>
      &nbsp;

      { drugSymptom.has_text
        ? drugSymptom.free_text
        : drugSymptom.symptom.name
      }

      { active && drugSymptom.is_parent &&
        <div className="list-group list-group-flush mt-3">
          {drugSymptom.children.map(childSymptom =>
            <DrugSymptom
              key={childSymptom.symptom.id}
              drugSymptom={childSymptom}
            />
          )}
        </div>
      }

      { active && !drugSymptom.is_parent && !drugSymptom.selected &&
        <div className="list-group-item no-hover">
          No figura
        </div>
      }

      { active && !drugSymptom.is_parent && drugSymptom.selected &&
        <div className="list-group-item no-hover" ref={symptomsRef}>
          <p>
            <strong>Frecuencia de aparición y gravedad:</strong>
            <span dangerouslySetInnerHTML={{ __html: drugSymptom.frequency }}></span>
          </p>

          <p>
            <strong>Duración de efecto:</strong>
            <span dangerouslySetInnerHTML={{ __html: drugSymptom.duration }}></span>
          </p>

          <p>
            <strong>Observaciones:</strong>
            <span dangerouslySetInnerHTML={{ __html: drugSymptom.observations }}></span>
          </p>

          <p>
            <strong>Alternativas:</strong>
            <span dangerouslySetInnerHTML={{ __html: drugSymptom.alternatives }}></span>
          </p>

          <p>
            <strong>Bibliografía:</strong>
            <span dangerouslySetInnerHTML={{ __html: drugSymptom.bibliography }}></span>
          </p>
        </div>
      }
    </div>
  )
}
