import React from "react"
import DataTable from 'react-data-table-component';

export const Table = ({ drugs, fetchDrug, filter, setFilter, params}) => {
  const sortable = filter.search == ''

  const columns = [
    {
      name: 'Principio activo',
      selector: 'active_ingredient',
      sortable: sortable,
      wrap: true
    },
    {
      name: 'Nombres comerciales',
      selector: 'trademarks',
      sortable: sortable,
      hide: "sm",
      wrap: true
    },
    {
      name: 'Clasificación ATC',
      selector: 'classification',
      sortable: sortable,
      hide: "sm",
      wrap: true
    },
    {
      name: '',
      sortable: false,
      hide: 'sm',
      right: true,
      cell: drug =>
        <div className="d-flex justify-content-end">
          <a href={`/admin/medicamentos/${drug.id}/editar`}
             className="btn btn-info"
          >
            <i className="fas fa-edit"></i>
          </a>
        </div>
    }
  ]

  const onSort = (column, sortDirection) => {
    setFilter({ ...filter, order: column.selector, direction: sortDirection})
  }

  const onChangePage = page => {
    setFilter({ ...filter, page: page })
  }

  return (
    <DataTable
      title="Fármacos"
      columns={columns}
      data={drugs}
      noDataComponent="No hay resultados"
      noTableHead={screen.width < 600}
      className="table"
      progressPending={params.loading}

      highlightOnHover
      pointerOnHover
      onRowClicked={row => fetchDrug(row.id)}

      pagination
      paginationServer
      paginationTotalRows={params.total}
      paginationPerPage={params.per_page}
      paginationRowsPerPageOptions={[]}
      paginationComponentOptions={{
        rangeSeparatorText: 'de',
        noRowsPerPage: true
      }}
      onChangePage={onChangePage}

      sortServer
      defaultSortField={filter.order}
      defaultSortAsc={filter.direction == 'asc'}
      onSort={onSort}
    />
  )
}
