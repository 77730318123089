import React, { useState } from "react"

// Components
import { Drugs }            from './drugs'
import { FilterList }       from './filterList'
import { ActiveIngredient } from './activeIngredient'

// Helpers
import { getRequest } from '../../helpers'

export const initialState = {
  query:    '',
  mode:     null,
  drugs:    [],
  loading:  false
}

export const MODES = {
  SEARCH:          'search',
  CLASSIFICATIONS: 'classifications',
  SYMPTOMS:        'symptoms'
}

const App = ({ classifications, symptoms }) => {
  const [state, setState]       = useState(initialState)

  const activateClassifications = () => setState(prevState => ({
    ...prevState,
    drugs:    [],
    mode:     MODES.CLASSIFICATIONS,
    loading:  true
  }))

  const activateSymptoms = () => setState({
    ...state,
    drugs:    [],
    mode:     MODES.SYMPTOMS,
    loading:  true
  })

  return(
    <section className="no__bg__color">
      <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12 medynut__welcome">
        <div className="col-lg-10 offset-lg-1 col-md-10 offset-md-1">

          <p className="title">
            Bienvenido a Medynut
          </p>

          <p className="description">
            La aplicación de consulta de medicamentos que afectan al estado nutricional
          </p>

          {
            (!state.mode || state.mode == MODES.SEARCH) &&
            <ActiveIngredient
              state    = {state}
              setState = {setState}
            />
          }

          { state.mode == MODES.CLASSIFICATIONS &&
            <FilterList
              list     = {classifications}
              type     = {MODES.CLASSIFICATIONS}
              setState = {setState}
            />
          }

          { state.mode == MODES.SYMPTOMS &&
            <FilterList
              list     = {symptoms}
              type     = {MODES.SYMPTOMS}
              setState = {setState}
            />
          }

          {
            state.mode == null &&
            <ul className="buttons-container row">
              <li className="col-lg-6 col-sm-12 nav-item">
                <button onClick={activateClassifications}>
                  Clasificacion ATC
                </button>
              </li>

              <li className="col-lg-6 col-sm-12 nav-item">
                <button onClick={activateSymptoms}>
                  Efecto adverso
                </button>
              </li>
            </ul>
          }

          <Drugs state={state}/>
        </div>
      </div>
    </section>
  )
}

export default App
