import React from "react"
import ChildRow from "./ChildRow.js"
import { TextEditor } from "../TextEditor"

export default class SymptomRow extends React.Component {
  constructor(props) {
    super(props)
    this.select       = this.select.bind(this)
    this.onChange     = this.onChange.bind(this)
    this.replaceChild = this.replaceChild.bind(this)
  }

  select() {
    const { symptom, replaceSymptom, index } = this.props
    if(symptom.selected && !window.confirm('¿Quieres eliminar el efecto adverso?')) return
    replaceSymptom(index, {...symptom, selected: !symptom.selected})
  }

  onChange(content, attribute) {
    const { symptom, replaceSymptom, index } = this.props
    replaceSymptom(index, {...symptom, [attribute]: content})
  }

  replaceChild(subIndex, child) {
    const { symptom, replaceSymptom, index } = this.props
    symptom.children[subIndex] = child
    replaceSymptom(index, symptom)
  }

  render () {
    const {symptom, index, symptomsSize} = this.props

    const uuid = symptom.symptom_id

    const nameFor = (attribute, id) => {
      return `drug[drug_symptoms_attributes][${uuid}][${attribute}]`
    }

    const idFor = (attribute) => {
      return `${attribute}_${uuid}`
    }

    return(
      symptom.selected
      ? <React.Fragment>
          <tr className="table-primary">
            <td className="d-flex justify-content-between align-items-center">
              {symptom.name}
              <i className="fas fa-times" onClick={this.select} />

              <input
                type="hidden"
                defaultValue={symptom.symptom_id}
                name={nameFor("symptom_id")}
              />
              { symptom.id
                ? <input
                    type="hidden"
                    defaultValue={symptom.id}
                    name={nameFor("id")}
                  />
                : ""
              }
            </td>
          </tr>
          { symptom.is_parent
            ? symptom.children.map((child, childIndex) =>
                <ChildRow
                  key={childIndex}
                  index={index}
                  childIndex={childIndex}
                  child={child}
                  replaceChild={this.replaceChild}
                  show={true}
                />
              )
            : <tr className="table-light"><td><div className="pl-3">
                <TextEditor
                  label="Frecuencia de aparición y gravedad"
                  attribute="frequency"
                  value={symptom.frequency}
                  id={idFor("frequency")}
                  name={nameFor("frequency")}
                  onChange={e => this.onChange(e, "frequency")}
                />

                <TextEditor
                  label="Duración de efecto"
                  attribute="duration"
                  value={symptom.duration}
                  id={idFor("duration")}
                  name={nameFor("duration")}
                  onChange={e => this.onChange(e, "duration")}
                />

                <TextEditor
                  label="Observaciones"
                  attribute="observations"
                  value={symptom.observations}
                  id={idFor("observations")}
                  name={nameFor("observations")}
                  onChange={e => this.onChange(e, "observations")}
                />

                <TextEditor
                  label="Alternativas (si las hay)"
                  attribute="alternatives"
                  value={symptom.alternatives}
                  id={idFor("alternatives")}
                  name={nameFor("alternatives")}
                  onChange={e => this.onChange(e, "alternatives")}
                />

                <TextEditor
                  label="Bibliografía"
                  attribute="bibliography"
                  value={symptom.bibliography}
                  id={idFor("bibliography")}
                  name={nameFor("bibliography")}
                  onChange={e => this.onChange(e, "bibliography")}
                />
              </div></td></tr>
          }
        </React.Fragment>
      : <tr onClick={this.select}><td>
          {symptom.name}

          { symptom.id &&
            <React.Fragment>
              <input
                type="hidden"
                defaultValue={true}
                name={nameFor("_destroy")}
              />
              <input
                type="hidden"
                defaultValue={symptom.id}
                name={nameFor("id")}
              />
              {/*Destroy all children when not selecting the parent*/}
              { symptom.is_parent &&
                symptom.children.map((child, childIndex) =>
                <React.Fragment key={childIndex}>
                  { child.id &&
                    <ChildRow
                      key={childIndex}
                      index={index}
                      childIndex={childIndex}
                      child={child}
                      replaceChild={this.replaceChild}
                      show={false}
                    />
                  }
                </React.Fragment>
              )}
            </React.Fragment>
          }
        </td></tr>
    )
  }
}



