const token = document.querySelector('meta[name="csrf-token"]')
const HEADERS = {
  credentials: 'same-origin',
  headers: {
    'Content-Type':     'application/json',
    'Accept':           'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Cache-Control':    'no-cache',
    'X-CSRF-Token':     token.getAttribute('content')
  }
}

export const getRequest = url => new Promise((resolve, reject) => {
    fetch(url, {
      credentials: 'same-origin',
      headers:      HEADERS
    })
    .then(response => response.json())
    .then(resolve)
    .catch(reject)
  }
)
