import React, { useState } from "react"

// Helpers
import { getRequest }         from '../../helpers'
import { MODES, initialState} from './app'

const url = query => `/medicamentos/search.json?q=${query}`

export const ActiveIngredient = ({ state, setState }) => {
  const [throttle, setThrottle] = useState(null)

  const onChange = event => {
    const query = event.currentTarget.value
    if(query.length == 0) return setState(initialState)

    setState(prevState => ({
      ...prevState,
      query,
      mode: MODES.SEARCH,
      loading: true})
    )

    if(throttle) {
      clearTimeout(throttle)
      setThrottle(null)
    }

    const timeout = setTimeout(() => {
      getRequest(url(query))
      .then(data => setState(prevState => ({
          ...prevState,
          drugs:   data.drugs,
          loading: false
        }))
      )
    }, 250)
    setThrottle(timeout)
  }

  return(
    <input
      value    = {state.query}
      onChange = {onChange}
    />
  )
}
