import React from "react"

export default class ClassificationRow extends React.Component {

  selectClassification = () => {
    const {classification, clickClassification, show} = this.props
    if (show) { return }

    this.props.clickClassification(classification.id)
  }

  resetClassification = () => {
    const {clickClassification, show} = this.props
    if (!show) { return }

    this.props.clickClassification(undefined)
  }


  render () {
    const {classification, show} = this.props
    return (
      <tr
        onClick={this.selectClassification}
        className={show ? "table-primary" : ""}
      >
        <td>{classification.code}</td>
        <td className="d-flex justify-content-between align-items-center">
          {classification.name}
          {show ?
            <i
              className="fas fa-times"
              onClick={this.resetClassification}
            >
            </i> : ""}
          {show ?
            <input
              type="hidden"
              value={classification.id}
              name="drug[classification_id]"
            /> : ""}
        </td>
      </tr>
    )
  }
}
