import React, { useState } from "react"

import { DrugSymptom } from './drug_symptom'

export const Modal = ({ drug, loading }) => {

  if(loading || !drug) return <div>Cargando...</div>

  return(
    <div className="modal fade" id="show-drug" tabIndex="-1">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">
              {drug.active_ingredient}
            </h3>

            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <h5>Marcas comerciales</h5>

            <ul className="list-group list-group-flush mb-3">
              { drug.trademarks.map(trademark =>
                <li className="list-group-item" key={trademark}>
                  {trademark}
                </li>
              )}
            </ul>

            <h5>Clasificación ATC</h5>

            <ul className="list-group list-group-flush mb-3">
              <li className="list-group-item">
                {drug.classification.name}
              </li>
            </ul>

            <h5>Información detallada</h5>

            <div dangerouslySetInnerHTML={{ __html: drug.details || "<p>N. d.</p>" }}/>

            <h5>Efectos adversos</h5>

            <div className="list-group list-group-flush mb-3">
              { drug.drug_symptoms.map(drugSymptom =>
                <DrugSymptom
                  key={drugSymptom.symptom.id}
                  drugSymptom={drugSymptom}
                />
              )}
            </div>
          </div>

          <div className="modal-footer">
            <a href={`/admin/medicamentos/${drug.id}/duplicate`}
               data-method="post"
               className="btn btn-info"
            >
              Duplicar
            </a>

            <a href={`/admin/medicamentos/${drug.id}/editar`}
               className="btn btn-info"
            >
              Editar
            </a>

            <a href={`/admin/medicamentos/${drug.id}`}
               className="btn btn-danger ml-2"
               data-confirm="¿Está seguro de que quiere borrar el medicamento?"
               data-method="delete"
            >
              Borrar
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
