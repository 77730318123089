import React, { useState } from "react"

export const Drugs = ({ state }) => {
  const { drugs, mode, loading } = state
  const setDrug = drug => window.location.href = drug.path

  if (mode == null || loading) return null

  if (drugs.length == 0 && !loading) return (
    <p className="no__results_found">No hay medicamentos para su búsqueda</p>
  )

  return (
    <ul className="filtered__list">
      { drugs.map(drug =>
        <li key={drug.id}
            onClick={() => setDrug(drug)}
            style={{cursor: 'pointer'}}
        >
          {drug.name}
        </li>
      )}
    </ul>
  )
}
