import React, { useState, useEffect, useRef } from "react"
import * as QuillTableUI from 'quill-table-ui'

Quill.register({
  'modules/tableUI': QuillTableUI.default
}, true)

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    ['link', 'video', 'image']
  ],
  table: true,
  tableUI: true
}

export const TextEditor = ({label, name, id, attribute, value, required, onChange}) => {
  const [focus,     setFocus]     = useState(false)
  const [textValue, setTextValue] = useState(value)
  const quillContainer            = useRef(null)

  const textChange = text => {
    onChange ? onChange(text, attribute) : setTextValue(text)
  }

  const addTable = quill => {
    const tableModule = quill.getModule('table')
    tableModule.insertTable(3, 3)
  }

  useEffect(() => {
    const quill = new Quill(quillContainer.current, {
      theme: 'snow',
      modules: modules
    })
    quill.on('text-change', () => textChange(quill.root.innerHTML))

    quill.on('selection-change', (range, oldRange) => {
      if(range && !oldRange) setFocus(true)
      if(!range && oldRange) setFocus(false)
    })

    if(value) {
      quill.clipboard.dangerouslyPasteHTML(0, value);
    }


    var toolbar = quill.getModule('toolbar')
    toolbar.addHandler('video', () => addTable(quill))
  }, [])

  return(
    <div>
      <label htmlFor={id}>
        {label}
      </label>

      <div className={`quill form-control ${focus ? '' : 'quill-inactive' }`}>
        <div ref={quillContainer}>
        </div>
      </div>

      <textarea
        name     = {name}
        value    = {(onChange ? value : textValue) || ''}
        onChange = {() => {}}
        hidden
      />
    </div>
  )
}

export default TextEditor
