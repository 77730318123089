import React, { useState, useEffect, useRef } from "react"

import { Table } from './table'
import { Modal } from './modal'

const token = document.querySelector('meta[name="csrf-token"]')

const params = {
  credentials: 'same-origin',
  headers: {
    'Content-Type':     'application/json',
    'Accept':           'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Cache-Control':    'no-cache',
    'X-CSRF-Token':     token.getAttribute('content')
  }
}

const Page = ({ isearch, ipage, iorder, idirection, per_page }) => {
  const [drugs,     setDrugs]    = useState([])
  const [total,     setTotal]    = useState(0)
  const [drug,      setDrug]     = useState(null)
  const [fetching,  setFetching] = useState(true)
  const [loading,   setLoading]  = useState(true)
  const [request,   setRequest]  = useState(null)
  const [filter,    setFilter]   = useState({
    search:    isearch,
    page:      ipage,
    order:     iorder,
    direction: idirection
  })


  const modalBtn = useRef(null)

  useEffect(() => {
    if (request) clearTimeout(request)

    let url
    const { search, page, order, direction } = filter

    if (search != "") {
      url = `/admin/medicamentos.json?&search=${search}`
    } else if (page == 1 && order == 'active_ingredient' && direction == 'asc') {
      url = '/admin/medicamentos.json'
    } else {
      url = `/admin/medicamentos.json?page=${page}&order=${order}&direction=${direction}`
    }

    setFetching(true)
    setRequest(setTimeout(() => fetchPage(url), 150))
  }, [filter])

  const fetchPage = url => {
    const new_state = window.location.origin + url.replace('.json', '')
    history.replaceState(history.state, document.title, new_state)

    fetch(url, params)
    .then(response => response.json())
    .catch(error => {
      setLoading(false)
      console.alert("Hay un error")
      console.warn(error)
    })
    .then(data => {
      setDrugs(data.drugs)
      setTotal(data.total_entries)
      setFetching(false)
    })
  }

  const fetchDrug = drugId => {
    setLoading(true)
    modalBtn.current.click()

    fetch(`/admin/medicamentos/${drugId}.json`, params)
    .then(response => response.json())
    .catch(error => {
      setLoading(false)
      setDrug({})
      console.alert("Hay un error")
      console.warn(error)
    })
    .then(data => {
      setLoading(false)
      setDrug(data.drug)
    })
  }

  return (
    <React.Fragment>
      <input
        type="search"
        value={filter.search}
        onChange={e => setFilter({...filter, search: e.currentTarget.value})}
        className="form-control mb-3"
      />

      <Table
        drugs={drugs}
        fetchDrug={fetchDrug}
        filter={filter}
        setFilter={setFilter}
        params={{
          per_page: per_page,
          total:    total,
          loading:  fetching
        }}
      />

      <button
        ref={modalBtn}
        data-toggle="modal"
        data-target="#show-drug"
        hidden
      >
      </button>

      <Modal
        drug={drug}
        loading={loading}
      />
    </React.Fragment>
  )
}

export default Page
