import React from "react"

export default class ClassificationHead extends React.Component {

  render () {
    const {classification, clickClassification} = this.props
    return (
      <thead>
        <tr>
          <th>{classification.code}</th>
          <th className="d-flex justify-content-between align-items-center">
            {classification.name}

            <i
              className="fas fa-step-backward"
              onClick={() => this.props.clickClassification(classification.parent_id)}
            >
            </i>
          </th>
        </tr>
      </thead>
    )
  }
}



