import React from "react"
import SymptomRow from "./SymptomRow.js"

export default class SymptomSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {symptoms: this.props.symptoms}
    this.replaceSymptom = this.replaceSymptom.bind(this)
  }

  replaceSymptom(index, symptom) {
    const symptoms = this.state.symptoms
    symptoms[index] = symptom
    this.setState({symptoms})
  }

  render () {
    return (
      <table className="table table-hover">
        <thead>
          <tr><th>
            Efectos adversos
          </th></tr>
        </thead>
        <tbody>
          {this.state.symptoms.map((symptom, index) => {
            return(
              <SymptomRow
                key={index}
                index={index}
                symptom={symptom}

                replaceSymptom={this.replaceSymptom}
              />
            )
          })}
        </tbody>
      </table>
    )
  }
}
