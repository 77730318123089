import React from "react"
import { TextEditor } from "../TextEditor"

export default class ChildRow extends React.Component {
  constructor(props) {
    super(props)
    this.select = this.select.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  select() {
    const { child, replaceChild, childIndex } = this.props
    if(child.selected && !window.confirm('¿Quieres eliminar el efecto adverso?')) return
    replaceChild(childIndex, {...child, selected: !child.selected})
  }

  onChange(content, attribute) {
    const { child, replaceChild, childIndex } = this.props
    replaceChild(childIndex, {...child, [attribute]: content})
  }

  render () {
    const {child, index, childIndex, show } = this.props

    const uuid = child.symptom_id

    const nameFor = (attribute) => {
      return `drug[drug_symptoms_attributes][${uuid}][${attribute}]`
    }

    const idFor = (attribute) => {
      return `${attribute}_${uuid}`
    }

    return(
      child.selected && show
      ? <React.Fragment>
          <tr className="table-secondary">
            <td>
              <div className="d-flex justify-content-between align-items-center pl-3">
                {child.name}

                <i className="fas fa-times" onClick={this.select}/>

                <input
                  type="hidden"
                  defaultValue={child.symptom_id}
                  name={nameFor("symptom_id")}
                />
                { child.id
                  ? <input
                      type="hidden"
                      defaultValue={child.id}
                      name={nameFor("id")}
                    />
                  : ""
                }
              </div>
            </td>
          </tr>

          <tr className="table-light">
            <td>
              <div className="pl-4">
                { child.has_free_text
                  ? <div className="form-group">
                      <label htmlFor={idFor("free_text")}>
                        Especifica el síntoma *
                      </label>
                      <input
                        className="form-control"
                        required
                        id={idFor("free_text")}
                        name={nameFor("free_text")}
                        onChange={e => this.onChange(e.target.value, "free_text")}
                        value={child.free_text || ""}
                      />
                    </div>
                  : ""
                }

                <TextEditor
                  label="Frecuencia de aparición y gravedad"
                  attribute="frequency"
                  value={child.frequency}
                  id={idFor("frequency")}
                  name={nameFor("frequency")}
                  onChange={e => this.onChange(e, "frequency")}
                />

                <TextEditor
                  label="Duración de efecto"
                  attribute="duration"
                  value={child.duration}
                  id={idFor("duration")}
                  name={nameFor("duration")}
                  onChange={e => this.onChange(e, "duration")}
                />

                <TextEditor
                  label="Observaciones"
                  attribute="observations"
                  value={child.observations}
                  id={idFor("observations")}
                  name={nameFor("observations")}
                  onChange={e => this.onChange(e, "observations")}
                />

                <TextEditor
                  label="Alternativas (si las hay)"
                  attribute="alternatives"
                  value={child.alternatives}
                  id={idFor("alternatives")}
                  name={nameFor("alternatives")}
                  onChange={e => this.onChange(e, "alternatives")}
                />

                <TextEditor
                  label="Bibliografía"
                  attribute="bibliography"
                  value={child.bibliography}
                  id={idFor("bibliography")}
                  name={nameFor("bibliography")}
                  onChange={e => this.onChange(e, "bibliography")}
                />
              </div>
            </td>
          </tr>
        </React.Fragment>
      : <React.Fragment>
          { show &&
            <tr>
              <td onClick={this.select}>
                <div className="pl-3">
                  {child.name}
                </div>
              </td>
            </tr>
          }
          { child.id &&
            <React.Fragment>
              <input
                type="hidden"
                defaultValue={true}
                name={nameFor("_destroy")}
              />
              <input
                type="hidden"
                defaultValue={child.id}
                name={nameFor("id")}
              />
            </React.Fragment>
          }
        </React.Fragment>
    )
  }
}
